import React, { useEffect, useState, useMemo } from "react";
import { Link, navigate } from "gatsby";
import { FaSpinner } from "react-icons/fa";
import Select from "react-select";
import countryList from "react-select-country-list";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Card,
} from "react-bootstrap";
import { Store } from "react-notifications-component";
import Multiselect from "multiselect-react-dropdown";
import Layout from "../components/Layout";
import Maps, { initialize } from "../components/Maps";
import { getCurrentUser, getToken } from "../utils/auth";
import Rating from "react-rating";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Notification } from "../components/Notification";
import {
  businessType,
  NotificationError,
  NotificationSuccess,
} from "../components/Helper";
import { geocodeByAddress } from "react-places-autocomplete";
import CSVReader from "react-csv-reader";

export default function AddlocationPage({ location, Header = true }) {
  const [showModel, setshowModel] = useState(false);
  const [Businnesses, setBusinnesses] = useState([]);
  const [Loading, setLoading] = useState(false);

  useEffect(() => {
    if (location?.state?.type) {
      window.MarchentInfo = {
        type: location?.state?.type,
        tagy: location?.state?.tagy,
      };
    } else if (!window.MarchentInfo?.type) {
      var user = getCurrentUser();
      window.MarchentInfo = {
        type: user?.type,
        tagy: user?.tagy,
      };
    }
    //if (!window.MarchentInfo?.type) navigate("/register");
    GetLocationsList(setBusinnesses);
    if (window.CSVLIst?.length > 0) {
      AddCsvFun();
    }
    window.backToAddLocation = undefined;
  }, []);

  async function AddCsvFun() {
    setLoading(true);
    var list = window.CSVLIst;
    var type = getCurrentUser()?.type ?? "";
    list.forEach(async (x, index) => {
      await getAdressApi(x, type, false, false, getloctions);
      if (list?.length === index + 1) {
        setLoading(false);
      }
    });
    window.CSVLIst = undefined;
  }

  function getloctions() {
    GetLocationsList(setBusinnesses);
  }

  const [showMap, setShowMap] = useState(false);
  const handleCloseMap = () => {
    window.MapInfo = undefined;
    GetLocationsList(setBusinnesses);
    setShowMap(false);
    setIsMap(true);
  };
  const handleShowMap = () => setShowMap(true);

  const [showFile, setShowFile] = useState(false);
  const [IsMap, setIsMap] = useState(true);

  const handleCloseFile = async () => {
    var checkToken = await getToken();
    window.MapInfo = {
      ...window.MapInfo,
      type: window.MarchentInfo?.type,
      tagy: window.MarchentInfo?.tagy,
      isBranch: true,
    };
    setShowFile(false);
    if (!checkToken) {
      window.backToAddLocation = true;
      navigate("/merchant-registration", {
        state: window.MapInfo,
      });
    } else {
      setIsMap(false);
    }
  };

  const handleShowFile = () => setShowFile(true);
  return (
    <Layout displayHeader={Header}>
      <Modal show={showMap} onHide={handleCloseMap} animation={true} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Business Info</Modal.Title>
        </Modal.Header>
        <Modal.Body className={`${IsMap && "align-self-center"} `}>
          <div className="maps-merchant" style={{ height: "442px;" }}>
            {IsMap && <Maps />}
            {!IsMap && <BusinessComponent handleCloseMap={handleCloseMap} />}
          </div>
        </Modal.Body>
        {IsMap && (
          <Modal.Footer>
            <Button variant="primary" onClick={handleCloseFile}>
              Save Changes
            </Button>
          </Modal.Footer>
        )}
      </Modal>

      <CsvUpload
        showFile={showFile}
        setShowFile={setShowFile}
        GetLocationsList={() => GetLocationsList(setBusinnesses)}
      />

      <main>
        <Container>
          <section className="mb-5">
            <div
              className={`${
                Header ? "col-xl-8 col-lg-8" : "col-xl-12 col-lg-12"
              } col-md-12 pb-5 mx-auto text-center`}
            >
              <Card className="border-0 shadow-none">
                <Card.Body className="mb-5 pb-5">
                  <Card.Title>
                    <h3>Business Info</h3>
                  </Card.Title>
                  <Card.Subtitle className="mb-4 text-dark">
                    Please input all of your locations
                  </Card.Subtitle>
                  <Button
                    variant="outline-primary"
                    className="p-3 mb-5"
                    onClick={() => {
                      handleShowMap();
                    }}
                  >
                    Add Location
                  </Button>

                  <div className="d-none">
                    <Maps />
                  </div>

                  {Loading && (
                    <>
                      <br />
                      <FaSpinner
                        icon="spinner"
                        className="spinner"
                        style={{ color: "#1478a8", fontSize: "60px" }}
                      />
                      <br />
                      <br />
                    </>
                  )}

                  <EditBusinessComponent
                    showModel={showModel}
                    setshowModel={setshowModel}
                    GetLocationsList={() => GetLocationsList(setBusinnesses)}
                  />

                  {(Businnesses ?? []).map((x) => (
                    <div
                      className="flex-grow-1 card-spacer mb-3 p-3 rounded text-start bg-light shadow-sm"
                      key={x?.ID}
                    >
                      <div className="d-flex align-items-center justify-content-between mb-10">
                        <div className="d-flex align-items-center mr-2">
                          <div>
                            <h4 className="font-size-h3 text-dark-75 text-hover-primary font-weight-bolder text-decoration-none text-dark">
                              {BranchName(x["BusinessName"], x["Branch"])}
                            </h4>
                            <div className="font-size-sm text-muted font-weight-bold mt-1">
                              {x?.AddresseText}
                            </div>
                          </div>
                        </div>
                        <div
                          className="label label-light label-inline font-weight-bold link-color border-0 opacity-8 py-4 px-3 font-size-sm"
                          onClick={() => {
                            setshowModel(x?.ID);
                          }}
                        >
                          <img
                            src="../img/location-add.svg"
                            className="pe-2"
                            alt="manage location"
                          />
                          Manage and Edit
                        </div>
                      </div>
                      {/*end::Item*/}
                    </div>
                  ))}
                </Card.Body>
                <Card.Footer className="bg-white border-0 mt-5 pt-5">
                  <div className="d-flex flex-column align-items-center">
                    <a
                      href="https://firebasestorage.googleapis.com/v0/b/bogosite.appspot.com/o/BOGO%20Multi-Unit%20Registration.csv?alt=media&token=c8533057-6ef7-4949-b1c6-2eb74267a1fb"
                      className=" mb-3  link-color border-0 opacity-8"
                      style={{ cursor: "pointer" }}
                    >
                      Download template
                    </a>
                    <a
                      className=" mb-3  link-color border-0 opacity-8"
                      style={{ cursor: "pointer" }}
                      onClick={handleShowFile}
                    >
                      Bulk upload through CSV spreadsheet
                    </a>
                    <button
                      className="btn btn-primary mt-3 text-white border-0 btn-google"
                      onClick={() => {
                        navigate(`/app/dashboard`);
                      }}
                    >
                      Submit
                    </button>
                  </div>
                </Card.Footer>
              </Card>
            </div>
          </section>
        </Container>
      </main>
    </Layout>
  );
}

function getTwentyFourHourTime(amPmString) {
  var d = new Date("1/1/2013 " + amPmString);
  return (
    (d.getHours() < 10 ? "0" + d.getHours() : d.getHours()) +
    ":" +
    (d.getMinutes() == 0 ? "00" : d.getMinutes())
  );
}

const BusinessComponent = ({ handleCloseMap, Businnesse, id }) => {
  const [selected, setSelected] = useState([]);
  const [formState, setFormState] = useState({});
  const [BussnesTag, setBussnesTag] = useState([]);
  const [CountryValue, setCountryValue] = useState();
  const options = useMemo(() => countryList().getData(), []);

  const changeHandler = (value) => {
    setCountryValue(value);
  };

  useEffect(() => {
    FirstFun();
  }, []);

  useEffect(() => {
    FirstFun();
    var type = Businnesse?.type;
    if (type) {
      var a = businessType.find((x) => x.Name === type);
      setBussnesTag([]);
      setTimeout(() => {
        setBussnesTag(a?.tagy ?? []);
      }, 1);
    }
  }, [Businnesse]);

  async function FirstFun() {
    var value = {};
    if (Businnesse) {
      value = Businnesse;
      value.timeCloseFriday = Businnesse?.BusinessHour[4]?.CloseTime;
      value.timeCloseMonday = Businnesse?.BusinessHour[0]?.CloseTime;
      value.timeCloseSaturday = Businnesse?.BusinessHour[5]?.CloseTime;
      value.timeCloseSunday = Businnesse?.BusinessHour[6]?.CloseTime;
      value.timeCloseThursday = Businnesse?.BusinessHour[3]?.CloseTime;
      value.timeCloseTuesday = Businnesse?.BusinessHour[1]?.CloseTime;
      value.timeCloseWednesday = Businnesse?.BusinessHour[2]?.CloseTime;
      value.timeOpenFriday = Businnesse?.BusinessHour[4]?.OpenTime;
      value.timeOpenMonday = Businnesse?.BusinessHour[0]?.OpenTime;
      value.timeOpenSaturday = Businnesse?.BusinessHour[5]?.OpenTime;
      value.timeOpenSunday = Businnesse?.BusinessHour[6]?.OpenTime;
      value.timeOpenThursday = Businnesse?.BusinessHour[3]?.OpenTime;
      value.timeOpenTuesday = Businnesse?.BusinessHour[1]?.OpenTime;
      value.timeOpenWednesday = Businnesse?.BusinessHour[2]?.OpenTime;
      value.rate = Businnesse?.StarRating;
      value.Price = Businnesse?.PriceRange;
      value.name = Businnesse?.Name;
      value.type = Businnesse?.Category?.Name;
      value.address = Businnesse?.Address?.AddressText;
      value.city = Businnesse?.Address?.City;
      value.zipcode = Businnesse?.Address?.ZipCode;
      value.Country = Businnesse?.Address?.Country;
      value.tags = window.MarchentInfo?.tagy;
      value.BusinessType = Businnesse?.type;
      setSelected(
        Businnesse?.Tags?.map((e) => {
          return e?.Name;
        }) ?? []
      );
    } else {
      value = window.MapInfo;
      value.timeCloseFriday = getTwentyFourHourTime(value?.timeCloseFriday);
      value.timeCloseMonday = getTwentyFourHourTime(value?.timeCloseMonday);
      value.timeCloseSaturday = getTwentyFourHourTime(value?.timeCloseSaturday);
      value.timeCloseSunday = getTwentyFourHourTime(value?.timeCloseSunday);
      value.timeCloseThursday = getTwentyFourHourTime(value?.timeCloseThursday);
      value.timeCloseTuesday = getTwentyFourHourTime(value?.timeCloseTuesday);
      value.timeCloseWednesday = getTwentyFourHourTime(
        value?.timeCloseWednesday
      );
      value.timeOpenFriday = getTwentyFourHourTime(value?.timeOpenFriday);
      value.timeOpenMonday = getTwentyFourHourTime(value?.timeOpenMonday);
      value.timeOpenSaturday = getTwentyFourHourTime(value?.timeOpenSaturday);
      value.timeOpenSunday = getTwentyFourHourTime(value?.timeOpenSunday);
      value.timeOpenThursday = getTwentyFourHourTime(value?.timeOpenThursday);
      value.timeOpenTuesday = getTwentyFourHourTime(value?.timeOpenTuesday);
      value.timeOpenWednesday = getTwentyFourHourTime(value?.timeOpenWednesday);
    }

    value.Price = value?.price ?? value.Price;

    var country = options.find(
      (x) => x.label === value.Country || x.label === value.country
    );
    setCountryValue(country);

    setFormState(value);
  }

  const handleChange = (e) => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    window.selected = selected.map((e) => ({ Name: e }));
  }, [selected]);

  function handleSubmit() {
    var tages = window.selected;
    let ThePrice = document.getElementsByName("Price")[0]?.value;
    if (ThePrice === "$") ThePrice = 1;
    if (ThePrice === "$$") ThePrice = 2;
    if (ThePrice === "$$$") ThePrice = 3;
    if (ThePrice === "$$$$") ThePrice = 4;
    if (ThePrice === "Not available") ThePrice = 0;
    if (ThePrice === "false") ThePrice = 0;
    let TheRate = document.getElementsByName("Rate")[0]?.value;
    if (TheRate === "" || TheRate === "undefined") TheRate = 0;
    let TimeOpenMonday = document.getElementsByName("TimeOpenMonday")[0].value;
    if (TimeOpenMonday === "") TimeOpenMonday = "Not available";
    let TimeCloseMonday =
      document.getElementsByName("TimeCloseMonday")[0].value;
    if (TimeCloseMonday === "") TimeCloseMonday = "Not available";

    let TimeOpenTuesday =
      document.getElementsByName("TimeOpenTuesday")[0].value;
    if (TimeOpenTuesday === "") TimeOpenTuesday = "Not available";
    let TimeCloseTuesday =
      document.getElementsByName("TimeCloseTuesday")[0].value;
    if (TimeCloseTuesday === "") TimeCloseTuesday = "Not available";

    let TimeOpenWednesday =
      document.getElementsByName("TimeOpenWednesday")[0].value;
    if (TimeOpenWednesday === "") TimeOpenWednesday = "Not available";
    let TimeCloseWednesday =
      document.getElementsByName("TimeCloseWednesday")[0].value;
    if (TimeCloseWednesday === "") TimeCloseWednesday = "Not available";

    let TimeOpenThursday =
      document.getElementsByName("TimeOpenThursday")[0].value;
    if (TimeOpenThursday === "") TimeOpenThursday = "Not available";
    let TimeCloseThursday =
      document.getElementsByName("TimeCloseThursday")[0].value;
    if (TimeCloseThursday === "") TimeCloseThursday = "Not available";

    let TimeOpenFriday = document.getElementsByName("TimeOpenFriday")[0].value;
    if (TimeOpenFriday === "") TimeOpenFriday = "Not available";
    let TimeCloseFriday =
      document.getElementsByName("TimeCloseFriday")[0].value;
    if (TimeCloseFriday === "") TimeCloseFriday = "Not available";

    let TimeOpenSaturday =
      document.getElementsByName("TimeOpenSaturday")[0].value;
    if (TimeOpenSaturday === "") TimeOpenSaturday = "Not available";
    let TimeCloseSaturday =
      document.getElementsByName("TimeCloseSaturday")[0].value;
    if (TimeCloseSaturday === "") TimeCloseSaturday = "Not available";

    let TimeOpenSunday = document.getElementsByName("TimeOpenSunday")[0].value;
    if (TimeOpenSunday === "") TimeOpenSunday = "Not available";
    let TimeCloseSunday =
      document.getElementsByName("TimeCloseSunday")[0].value;
    if (TimeCloseSunday === "") TimeCloseSunday = "Not available";

    var body = JSON.stringify({
      Name: document.getElementsByName("BusinessName")[0].value,
      Website: formState.Url,
      Address: {
        Branch: document.getElementsByName("Branch")[0].value,
        AddressText: document.getElementsByName("Address1")[0].value,
        City: document.getElementsByName("City")[0].value,
        ZipCode: document.getElementsByName("Zip")[0].value,
        Country: CountryValue?.label,
      },
      Category: { Name: formState.BusinessType },
      Tags: tages,
      BusinessHour: [
        {
          DayName: "Monday",
          OpenTime: TimeOpenMonday,
          CloseTime: TimeCloseMonday,
        },
        {
          DayName: "Thusday",
          OpenTime: TimeOpenTuesday,
          CloseTime: TimeCloseTuesday,
        },
        {
          DayName: "Wednesday",
          OpenTime: TimeOpenWednesday,
          CloseTime: TimeCloseWednesday,
        },
        {
          DayName: "Thursday",
          OpenTime: TimeOpenThursday,
          CloseTime: TimeCloseThursday,
        },
        {
          DayName: "Friday",
          OpenTime: TimeOpenFriday,
          CloseTime: TimeCloseFriday,
        },
        {
          DayName: "Saturday",
          OpenTime: TimeOpenSaturday,
          CloseTime: TimeCloseSaturday,
        },
        {
          DayName: "Sunday",
          OpenTime: TimeOpenSunday,
          CloseTime: TimeCloseSunday,
        },
      ],
      StarRating: TheRate,
      PriceRange: ThePrice,
    });
    if (Businnesse) updateLocation(id, body);
    else addLocation(body, handleCloseMap);
  }

  return (
    <div>
      <div className="col-xl-12 col-lg-12 col-md-12 pb-5 mx-auto text-center">
        <form
          onSubmit={handleSubmit}
          name="merchant-register-data"
          method="post"
          data-netlify-recaptcha="true"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          autocomplete="off"
          id="address-form"
          className="text-start"
        >
          <input
            type="hidden"
            name="form-name"
            value="merchant-register-data"
          />
          <div className="displaydiv">
            <Row className="mb-3 text-start">
              <Form.Group className="mb-3 text-start">
                <Form.Label className="bogo-par fs-13 text-start fw-airlight">
                  Business Type *
                </Form.Label>
                <Select
                  value={{
                    value: formState.BusinessType,
                    label: formState.BusinessType,
                  }}
                  onChange={(e) => {
                    var a = businessType.find((x) => x.Name === e.value);
                    setBussnesTag([]);
                    setTimeout(() => {
                      setSelected([]);
                      setBussnesTag(a?.tagy ?? []);
                      setFormState({
                        ...formState,
                        BusinessType: e.value,
                      });
                    }, 1);
                  }}
                  options={businessType?.map((e) => {
                    return { value: e.Name, label: e.Name };
                  })}
                />
              </Form.Group>
              <Form.Group controlId="formGridBusiness" className="mb-3">
                <Form.Label className="bogo-par fs-13 text-start fw-airlight">
                  Business Name *
                </Form.Label>
                <Form.Control
                  type="text"
                  name="BusinessName"
                  id="BusinessName"
                  onChange={handleChange}
                  defaultValue={formState.name}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formGridBusiness" className="mb-3">
                <Form.Label className="bogo-par fs-13 text-start fw-airlight">
                  Branch Name
                </Form.Label>
                <Form.Control
                  type="text"
                  name="Branch"
                  id="Branch"
                  onChange={handleChange}
                  defaultValue={formState?.Address?.Branch}
                  required
                />
              </Form.Group>
            </Row>
            <Form.Group
              className="mb-3 text-start"
              controlId="formGridAddress1"
            >
              <Form.Label className="bogo-par fs-13 text-start fw-airlight">
                Address *
              </Form.Label>
              <input
                onChange={handleChange}
                defaultValue={formState.address}
                name="Address1"
                id="Address1"
                required
                className="form-control"
              />
            </Form.Group>
            <Row className="mb-3 text-start">
              <Form.Group as={Col} controlId="formGridCity">
                <Form.Label className="bogo-par fs-13 text-start fw-airlight">
                  State/City *
                </Form.Label>
                <Form.Control
                  /*  ref={inputEl} */
                  name="City"
                  onChange={handleChange}
                  defaultValue={formState.city}
                  id="City"
                  required
                />
              </Form.Group>
              <Form.Group as={Col} controlId="formGridZip">
                <Form.Label className="bogo-par fs-13 text-start fw-airlight">
                  Postal Code
                </Form.Label>
                <Form.Control
                  name="Zip"
                  onChange={handleChange}
                  defaultValue={formState.zipcode}
                  id="Zip"
                />
              </Form.Group>
            </Row>

            <Row className="mb-3 text-start">
              <Form.Group as={Col} controlId="formGridCountry">
                <Form.Label className="bogo-par fs-13 text-start fw-airlight">
                  Country *
                </Form.Label>
                <Select
                  options={options}
                  value={CountryValue}
                  onChange={changeHandler}
                />
              </Form.Group>
              <Form.Group
                as={Col}
                controlId="formGridZip"
                style={{ display: "none" }}
              >
                <Form.Label className="bogo-par fs-13 text-start fw-airlight">
                  Category
                </Form.Label>
                <Form.Control
                  name="Category"
                  onChange={handleChange}
                  defaultValue={formState.Category}
                  id="Category"
                  required
                />
              </Form.Group>
            </Row>
            <Form.Group className="mb-3 text-start">
              <Form.Label className="bogo-par fs-13 text-start fw-airlight">
                Tags
              </Form.Label>
              <Multiselect
                selectionLimit={5}
                isObject={false}
                className="px-0"
                showArrow={true}
                selectedValues={selected}
                onSelect={(e) => {
                  setSelected(e);
                }}
                onRemove={(e) => {
                  setSelected(e);
                }}
                options={BussnesTag?.map((e) => {
                  return e;
                })}
              />
            </Form.Group>

            <Row className="mb-3 text-start">
              <Form.Group as={Col} controlId="formGridCountry">
                <Form.Label className="bogo-par fs-13 text-start fw-airlight">
                  <b>Monday</b> : Open
                </Form.Label>
                <Form.Control
                  name="TimeOpenMonday"
                  type="time"
                  onChange={handleChange}
                  defaultValue={formState?.timeOpenMonday}
                  id="TimeOpenMonday"
                />
              </Form.Group>
              <Form.Group as={Col} controlId="formGridZip">
                <Form.Label className="bogo-par fs-13 text-start fw-airlight">
                  Close
                </Form.Label>
                <Form.Control
                  name="TimeCloseMonday"
                  type="time"
                  onChange={handleChange}
                  defaultValue={formState.timeCloseMonday}
                  id="TimeCloseMonday"
                />
              </Form.Group>
            </Row>
            <Row className="mb-3 text-start">
              <Form.Group as={Col} controlId="formGridCountry">
                <Form.Label className="bogo-par fs-13 text-start fw-airlight">
                  <b>Tuesday</b> : Open
                </Form.Label>
                <Form.Control
                  name="TimeOpenTuesday"
                  type="time"
                  onChange={handleChange}
                  defaultValue={formState.timeOpenTuesday}
                  id="TimeOpenTuesday"
                  required
                />
              </Form.Group>
              <Form.Group as={Col} controlId="formGridZip">
                <Form.Label className="bogo-par fs-13 text-start fw-airlight">
                  Close
                </Form.Label>
                <Form.Control
                  name="TimeCloseTuesday"
                  type="time"
                  onChange={handleChange}
                  defaultValue={formState.timeCloseTuesday}
                  id="TimeCloseTuesday"
                  required
                />
              </Form.Group>
            </Row>

            <Row className="mb-3 text-start">
              <Form.Group as={Col} controlId="formGridCountry">
                <Form.Label className="bogo-par fs-13 text-start fw-airlight">
                  <b>Wednesday</b> : Open
                </Form.Label>
                <Form.Control
                  name="TimeOpenWednesday"
                  type="time"
                  onChange={handleChange}
                  defaultValue={formState.timeOpenWednesday}
                  id="TimeOpenWednesday"
                  required
                />
              </Form.Group>
              <Form.Group as={Col} controlId="formGridZip">
                <Form.Label className="bogo-par fs-13 text-start fw-airlight">
                  Close
                </Form.Label>
                <Form.Control
                  name="TimeCloseWednesday"
                  type="time"
                  onChange={handleChange}
                  defaultValue={formState.timeCloseWednesday}
                  id="TimeCloseWednesday"
                  required
                />
              </Form.Group>
            </Row>

            <Row className="mb-3 text-start">
              <Form.Group as={Col} controlId="formGridCountry">
                <Form.Label className="bogo-par fs-13 text-start fw-airlight">
                  <b>Thursday</b> : Open
                </Form.Label>
                <Form.Control
                  name="TimeOpenThursday"
                  type="time"
                  onChange={handleChange}
                  defaultValue={formState.timeOpenThursday}
                  id="TimeOpenThursday"
                  required
                />
              </Form.Group>
              <Form.Group as={Col} controlId="formGridZip">
                <Form.Label className="bogo-par fs-13 text-start fw-airlight">
                  Close
                </Form.Label>
                <Form.Control
                  name="TimeCloseThursday"
                  type="time"
                  onChange={handleChange}
                  defaultValue={formState.timeCloseThursday}
                  id="TimeCloseThursday"
                  required
                />
              </Form.Group>
            </Row>

            <Row className="mb-3 text-start">
              <Form.Group as={Col} controlId="formGridCountry">
                <Form.Label className="bogo-par fs-13 text-start fw-airlight">
                  <b>Friday</b> : Open
                </Form.Label>
                <Form.Control
                  name="TimeOpenFriday"
                  type="time"
                  onChange={handleChange}
                  defaultValue={formState.timeOpenFriday}
                  id="TimeOpenFriday"
                  required
                />
              </Form.Group>
              <Form.Group as={Col} controlId="formGridZip">
                <Form.Label className="bogo-par fs-13 text-start fw-airlight">
                  Close
                </Form.Label>
                <Form.Control
                  name="TimeCloseFriday"
                  type="time"
                  onChange={handleChange}
                  defaultValue={formState.timeCloseFriday}
                  id="TimeCloseFriday"
                  required
                />
              </Form.Group>
            </Row>

            <Row className="mb-3 text-start">
              <Form.Group as={Col} controlId="formGridCountry">
                <Form.Label className="bogo-par fs-13 text-start fw-airlight">
                  <b>Saturday</b> : Open
                </Form.Label>
                <Form.Control
                  name="TimeOpenSaturday"
                  type="time"
                  onChange={handleChange}
                  defaultValue={formState.timeOpenSaturday}
                  id="TimeOpenSaturday"
                  required
                />
              </Form.Group>
              <Form.Group as={Col} controlId="formGridZip">
                <Form.Label className="bogo-par fs-13 text-start fw-airlight">
                  Close
                </Form.Label>
                <Form.Control
                  name="TimeCloseSaturday"
                  type="time"
                  onChange={handleChange}
                  defaultValue={formState.timeCloseSaturday}
                  id="TimeCloseSaturday"
                  required
                />
              </Form.Group>
            </Row>

            <Row className="mb-3 text-start">
              <Form.Group as={Col} controlId="formGridCountry">
                <Form.Label className="bogo-par fs-13 text-start fw-airlight">
                  <b>Sunday</b> : Open
                </Form.Label>
                <Form.Control
                  name="TimeOpenSunday"
                  type="time"
                  onChange={handleChange}
                  defaultValue={formState.timeOpenSunday}
                  id="TimeOpenSunday"
                  required
                />
              </Form.Group>
              <Form.Group as={Col} controlId="formGridZip">
                <Form.Label className="bogo-par fs-13 text-start fw-airlight">
                  Close
                </Form.Label>
                <Form.Control
                  name="TimeCloseSunday"
                  type="time"
                  onChange={handleChange}
                  defaultValue={formState.timeCloseSunday}
                  id="TimeCloseSunday"
                  required
                />
              </Form.Group>
            </Row>
            <Form.Group className="mb-3 text-start">
              <Form.Label className="bogo-par fs-13 text-start fw-airlight w-100">
                Rating
              </Form.Label>
              <input
                type="text"
                style={{ display: "none" }}
                id="Rate"
                name="Rate"
                value={formState.rate}
              />
              <div id="rate-disbled">
                <Rating
                  initialRating={formState.rate}
                  name="Rate"
                  id="Rate"
                  readonly="true"
                  emptySymbol={
                    <FontAwesomeIcon
                      icon={faStar}
                      data-prefix="far"
                      className="me-2 fa-lg far text-secondary"
                    />
                  }
                  fullSymbol={
                    <FontAwesomeIcon
                      icon={faStar}
                      className="me-2 fa-lg text-warning"
                    />
                  }
                />

                <Form.Label className="bogo-par fs-13 text-start fw-airlight ">
                  {formState.Review} Google reviews
                </Form.Label>
              </div>
            </Form.Group>

            <Form.Group controlId="formGridPhone" className="mb-3 text-start">
              <Form.Label className="bogo-par fs-13 text-start fw-airlight">
                Price Range
              </Form.Label>

              <Form.Control
                name="Price"
                type="text"
                onChange={handleChange}
                value={
                  (+formState.Price === 1 && "$") ||
                  (+formState.Price === 2 && "$$") ||
                  (+formState.Price === 3 && "$$$") ||
                  (+formState.Price === 4 && "$$$$") ||
                  ((formState.Price === "undefined" ||
                    formState.Price === "" ||
                    !formState?.Price) &&
                    "Not available")
                }
                id="Price"
                required
                disabled
              />
            </Form.Group>
            <Form.Group controlId="formGridPhone" className="mb-3 text-start" />
            <Button
              variant="primary"
              className="w-100 fs-14 fw-bold h-52 show"
              onClick={() => handleSubmit()}
            >
              Submit Details
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

const EditBusinessComponent = ({
  showModel,
  setshowModel,
  GetLocationsList,
}) => {
  const [Businnesse, setBusinnesse] = useState(false);

  useEffect(() => {
    GetBusinnessesById(showModel, setBusinnesse);
  }, [showModel]);

  const handleCloseModel = () => {
    setBusinnesse(false);
    setshowModel(false);
    GetLocationsList();
  };

  return (
    <Modal
      show={showModel}
      onHide={handleCloseModel}
      animation={true}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>Business Info {showModel}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="">
        <div className="maps-merchant" style={{ height: "442px;" }}>
          {Businnesse && (
            <BusinessComponent
              handleCloseMap={handleCloseModel}
              Businnesse={Businnesse}
              id={showModel}
            />
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

const CsvUpload = ({ showFile, setShowFile, GetLocationsList }) => {
  const [list, setlist] = useState([]);
  const [fileInfo, setfileInfo] = useState({});
  const [Loading, setLoading] = useState(false);

  const handleCloseModel = () => {
    LoadingFun(false);
    GetLocationsList();
    setShowFile(false);
    setlist([]);
    setfileInfo();
  };

  const handleForce = (data, Info) => {
    var error = false;
    data.forEach((element) => {
      if (!error)
        error =
          element["Business Name*"] == "" ||
          element["Business Name*"] == null ||
          element["Branch Name*"] == "" ||
          element["Branch Name*"] == null ||
          element["Address *"] == "" ||
          element["Address *"] == null ||
          element["City*"] == "" ||
          element["City*"] == null ||
          element["Country*"] == "" ||
          element["Country*"] == null;
    });

    if (error) {
      Store.addNotification({
        title: "Error!",
        message: "There is an empty field in the Csv file",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
      return;
    }

    setlist(data);
    setfileInfo(Info);
  };

  const papaparseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
  };

  function LoadingFun(params) {
    setLoading();
    setTimeout(() => {
      setLoading(params);
    }, 1);
  }

  return (
    <Modal show={showFile} onHide={handleCloseModel} animation={true} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Business Info</Modal.Title>
      </Modal.Header>
      <Modal.Body className="col-12">
        <p>Please upload your spreadsheets</p>
        <div className="d-none">
          <CSVReader
            cssClass="react-csv-input"
            label="Select CSV with secret Death Star statistics"
            onFileLoaded={handleForce}
            parserOptions={papaparseOptions}
          />
        </div>
        <section className="container">
          <div
            role="button"
            tabIndex={0}
            onClick={() => {
              document.getElementById("react-csv-reader-input").click();
            }}
            style={{
              flex: "1 1 0%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "20px",
              borderWidth: "2px",
              borderRadius: "2px",
              borderColor: "rgb(238, 238, 238)",
              borderStyle: "dashed",
              backgroundColor: "rgb(250, 250, 250)",
              color: "rgb(189, 189, 189)",
              outline: "none",
              transition: "border 0.24s ease-in-out 0s",
            }}
          >
            <img src="../img/upload.svg" alt="upload file" className="mb-3" />
            <p className="fw-bolder">Upload Your Files</p>
            <em>(accepted format: .csv, max 2mb)</em>
          </div>
          <aside className="mt-3">
            {fileInfo?.name && (
              <h4>File : {fileInfo?.name + " - " + fileInfo?.size} bytes</h4>
            )}
            <ul />
          </aside>
        </section>
        {list?.length > 0 && (
          <>
            <br />
            <br />
          </>
        )}
        {(list ?? []).map((x) => (
          <div
            className="flex-grow-1 card-spacer mb-3 p-3 rounded text-start bg-light shadow-sm"
            key={x["Business Name*"]}
          >
            <div className="d-flex align-items-center justify-content-between mb-10">
              <div className="d-flex align-items-center mr-2">
                <div>
                  <h4 className="font-size-h3 text-dark-75 text-hover-primary font-weight-bolder text-decoration-none text-primarylocal">
                    {BranchName(x["Business Name*"], x["Branch Name*"])}
                  </h4>
                  <div className="font-size-sm text-dark-75 font-weight-bold mt-1">
                    Business Name : {x["Business Name*"]}
                  </div>
                  <div className="font-size-sm text-muted font-weight-bold mt-1">
                    {x["Address *"]}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          disabled={Loading}
          onClick={() => {
            if (!(list?.length > 0)) return false;
            var checkToken = getToken();
            var type = getCurrentUser()?.type ?? "";
            LoadingFun(true);
            if (!checkToken) {
              var y = list[0];
              getAdressApi(y, type, false, true).then((x) => {
                window.MapInfo = {
                  ...x,
                  type: window.MarchentInfo?.type,
                  tagy: window.MarchentInfo?.tagy,
                  isBranch: true,
                  NextStep: true,
                  name: y["Business Name*"],
                  Branch: y["Branch Name*"],
                  country: y["Country*"],
                  city: x?.city ?? y["City*"],
                };
                list.shift();
                window.CSVLIst = list;
                window.backToAddLocation = true;

                LoadingFun(false);

                navigate("/merchant-registration", {
                  state: window.MapInfo,
                });
              });
            } else {
              var count = list.length;
              list.forEach((x, i) => {
                getAdressApi(x, type, false, false).then((x) => {
                  /* debugger;
                  count--;
                  if (count === 0) {
                    LoadingFun(false);
                  } */
                });
              });
            }
          }}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const getAdressApi = (
  x,
  type,
  handleCloseMap,
  back = false,
  GetLocationsList = false
) => {
  return new Promise(function (resolve, reject) {
    geocodeByAddress(
      `${x["Business Name*"]},${x["Address *"]} - ${x["City*"]} - ${x["Country*"]}`
    )
      .then((results) => {
        initialize(results[0]?.place_id, true).then((e) => {
          var value = e;
          var body = JSON.stringify({
            Name: x["Business Name*"],
            Website: value?.url,
            Address: {
              Branch: x["Branch Name*"],
              AddressText: /* value?.address ?? */ x["Address *"],
              City: /* value?.city ?? */ x["City*"],
              ZipCode: value?.zipcode,
              Country: /* value?.country ?? */ x["Country*"],
            },
            Category: { Name: type },
            Tags: value?.tags.map((e) => ({ Name: e })),
            BusinessHour: [
              {
                DayName: "Monday",
                OpenTime: value?.timeOpenMonday,
                CloseTime: value?.timeCloseMonday,
              },
              {
                DayName: "Thusday",
                OpenTime: value?.timeOpenTuesday,
                CloseTime: value?.timeCloseTuesday,
              },
              {
                DayName: "Wednesday",
                OpenTime: value?.timeOpenWednesday,
                CloseTime: value?.timeCloseWednesday,
              },
              {
                DayName: "Thursday",
                OpenTime: value?.timeOpenThursday,
                CloseTime: value?.timeCloseThursday,
              },
              {
                DayName: "Friday",
                OpenTime: value?.timeOpenFriday,
                CloseTime: value?.timeCloseFriday,
              },
              {
                DayName: "Saturday",
                OpenTime: value?.timeOpenSaturday,
                CloseTime: value?.timeCloseSaturday,
              },
              {
                DayName: "Sunday",
                OpenTime: value?.timeOpenSunday,
                CloseTime: value?.timeCloseSunday,
              },
            ],
            StarRating: value?.rate,
            PriceRange: value?.price,
          });

          if (back) {
            resolve(window.MapInfo);
            return;
          }
          addLocation(body, handleCloseMap, `for ${value?.address}`)
            .then(() => {
              if (GetLocationsList) GetLocationsList();
              resolve(true);
              return;
            })
            .catch((error) => {
              console.error("Error", error);
              resolve(true);
              return;
            });
        });
      })
      .catch((error) => {
        console.error("Error", error);
        resolve(true);
        return;
      });
  });
};

async function GetLocationsList(setBusinnesses) {
  if (!getToken()) return;
  return await fetch(`${window.$apiurl}/api/Merchant/GetBusinnesses`, {
    method: "get",
    withCredentials: true,
    headers: {
      Accept: "application/json, text/plain, */*",
      Authorization: `bearer ${getToken()}`,
      "X-FP-API-KEY": "iphone",
      "Content-Type": "application/json",
    },
  })
    .then(async (response) => {
      const statusCode = await response.status;
      const data = await response.json();
      return Promise.all([statusCode, data]);
    })
    .then(async (res) => {
      if (res[1].length > 0) setBusinnesses(res[1]);
    })
    .catch((error) => {
      console.error(error);
    });
}

async function addLocation(body, handleCloseMap, txtMsg = "") {
  Notification("success", "Note has been saved");
  return fetch(window.$apiurl + `/api/Merchant/AddNewLocation`, {
    method: "post",
    withCredentials: true,
    headers: {
      Authorization: `bearer ${getToken()}`,
      "X-FP-API-KEY": "iphone",
      "Content-Type": "application/json",
    },
    body: body,
  })
    .then(async (response) => {
      const statusCode = response.status;
      const data =
        statusCode === 200 || statusCode === 201 ? "" : await response.json();
      return Promise.all([statusCode, data]);
    })
    .then(async (res) => {
      if (res[0] === 200 || res[0] === 201) {
        NotificationSuccess({ message: "Add Location is Success " + txtMsg });
        if (handleCloseMap) handleCloseMap();
      } else {
        NotificationError({ message: res[1] });
      }
    });
}

async function updateLocation(id, body) {
  return fetch(`${window.$apiurl}/api/Merchant/UpdateBusinessLocation/${id}`, {
    method: "PATCH",
    withCredentials: true,
    headers: {
      Authorization: `bearer ${getToken()}`,
      "X-FP-API-KEY": "iphone",
      "Content-Type": "application/json",
    },
    body: body,
  })
    .then(async (response) => {
      const statusCode = response.status;
      const data =
        statusCode === 200 || statusCode === 201 ? "" : await response.json();
      return Promise.all([statusCode, data]);
    })
    .then(async (res) => {
      if (res[0] === 200 || res[0] === 201) {
        Store.addNotification({
          title: "Success!",
          message: "update Location is Success",
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        });
      } else {
        NotificationError({ message: "Error" });
      }
    });
}

async function GetBusinnessesById(id, setBusinnesse) {
  if (!id) return;
  return await fetch(
    `${window.$apiurl}/api/Merchant/GetBusinnessesById/` + id,
    {
      method: "get",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${getToken()}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
    }
  )
    .then(async (response) => {
      const statusCode = await response.status;
      const data = await response.json();
      return Promise.all([statusCode, data]);
    })
    .then(async (res) => {
      if (res[0] === 200) {
        res[1].ID = id;
        res[1].Id = id;
        setBusinnesse(res[1]);
      } else setBusinnesse();
    })
    .catch((error) => {
      console.error(error);
    });
}

const BranchName = (name, branch) => {
  var r = branch;
  if (branch == "" || branch == null) r = name;
  return r;
};
